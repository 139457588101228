import React, { useContext } from "react";
import { CartContext } from "../context/CartContext";
import { Layout, Cart, Seo } from "../components";

export default function CartPage() {
	const { checkout } = useContext(CartContext);

	return (
		<>
			<Layout product={true}>
				<Seo title='Basket' description='NiteHawk basket' />
				<Cart checkout={checkout} />
			</Layout>
		</>
	);
}
